<template>
  <div class="content-wrap-tax">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm" label-width="100px">
          <div>
            <el-form-item label="组织编码" prop="uniqueCode">
              <el-input v-model.trim="queryForm.uniqueCode" placeholder="请输入组织编码" maxlength="30" @keyup.enter.native="handleGetBillingTradeList()" />
            </el-form-item>
            <el-form-item label="组织名称" prop="queryForm">
              <OrganizationSelect :model="queryForm"></OrganizationSelect>
            </el-form-item>
            <el-form-item label="可开票税率" prop="taxRate">
              <el-select v-model="queryForm.taxRate" placeholder="请选择">
                <el-option v-for="item in invoiceTaxRateLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item label="发票类型" prop="dataId">
              <el-select v-model="queryForm.billingType" placeholder="请选择">
                <el-option v-for="item in billingTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="启用状态" prop="status">
              <el-select v-model="queryForm.status" placeholder="请选择">
                <el-option v-for="item in splitLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否独立开票" prop="separateBilling">
              <el-select v-model="queryForm.separateBilling" placeholder="请选择">
                <el-option v-for="item in independentBillingListSelect" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select> </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="handleQuery" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <span>票据列表</span>
          <div>
            <el-button @click="handleDetailInvoice" type="primary">新 增</el-button>
            <el-button @click="handleDeleteAllSelect" :disabled="mergeBillData.length == 0 ? true : false" type="primary">批量删除</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table border stripe :data="tableData" ref="retailTable" v-loading="recordsLoading" :row-key="handleGetRowKey" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column type="selection" width="50" :reserve-selection="true" fixed="left" />
            <el-table-column type="index" label="序号" width="60" fixed="left" class-name="dl-pointer" />
            <el-table-column prop="uniqueCode" label="组织编码" width="180" />
            <el-table-column prop="name" label="组织名称" min-width="220" show-overflow-tooltip />
            <el-table-column prop="taxRate" label="可开票税率" :formatter="handleTableValueFormat" width="170" />
            <el-table-column prop="billingType" label="默认发票类型" :formatter="fmtBillingType" min-width="120" />
            <el-table-column prop="status" label="启用状态" :formatter="handleTableValueFormat" width="170" />
            <el-table-column prop="updaterName" label="更新人" width="170" />
            <el-table-column prop="updated" label="更新时间" width="170" />
            <el-table-column prop="separateBilling" label="是否独立开票" :formatter="handleTableValueFormat" width="170" />
            <el-table-column prop="operate" label="操作" fixed="right" width="110">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetailInvoice(scope.row, 'Modify')">修改</el-button>
                <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryForm.page" :page-sizes="pageSizes" :page-size="queryForm.size" layout="total, sizes, prev, pager, next" :total="dataTotal"></el-pagination>
      </div>
    </div>

    <!-- 新增-修改 -->
    <div class="add_style">
      <el-dialog :title="ModifyOrAdd" :visible.sync="dialogAdd" width="30%">
        <el-form :model="addParameter" :rules="rules" ref="ruleForm">
          <el-form-item prop="orgId" style="margin-bottom: 15px" label="组织名称" :label-width="formLabelWidth">
            <OrganizationSelect :is-add-all="false" allLabel="请选择" :model="addParameter"></OrganizationSelect>
          </el-form-item>
          <el-form-item prop="taxRate" style="margin-bottom: 15px" label="可开票税率" :label-width="formLabelWidth">
            <el-select v-model="addParameter.taxRate" placeholder="请选择">
              <el-option v-for="item in invoiceTaxRateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 15px" label="默认发票类型" :label-width="formLabelWidth">
            <el-select v-model="addParameter.billingType" placeholder="请选择">
              <el-option v-for="item in billingTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 15px" label="是否独立开票" :label-width="formLabelWidth">
            <el-select v-model="addParameter.separateBilling" placeholder="请选择">
              <el-option v-for="item in independentBillingList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="status" style="margin-bottom: 15px" label="启用状态" :label-width="formLabelWidth">
            <el-select v-model="addParameter.status" placeholder="请选择">
              <el-option v-for="item in splitList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogAdd = false">取 消</el-button>
          <el-button type="primary" @click="addAffirm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { tableStyle, dateOption, changeMethods, commonsMethods } from '@/util/mixins';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import { getTaxRateList, addSalTaxRate, taxRateBatchDelete, taxRateList, revampTaxRateList, salTaxRate, salBillTypeListGet, billtypeGetSalBillingTypeList } from '@/service/invoiceOpenConfiguration.js';
export default {
  name: 'taxRate',
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {
    OrganizationSelect
  },
  props: [],
  data() {
    return {
      ModifyOrAdd: '',
      dialogAdd: false,
      addParameter: {
        name: '',
        orgId: '',
        taxRate: '',
        billingType: '',
        status: '1',
        separateBilling:'N'
      },
      formLabelWidth: '120px',
      queryForm: {
        uniqueCode: '',
        billingType: '',
        status: '',
        orgId: 0,
        name: '',
        taxRate: '',
        separateBilling:'',
        page: 1,
        size: 15
      },
      splitList: [
        { value: '0', label: '启用' },
        { value: '1', label: '禁用' }
      ],
      splitLists: [],
      invoiceTaxRateList: [
        { value: 0.00, label: '0%' },
        { value: 0.01, label: '1%' },
        { value: 0.015, label: '1.5%' },
        { value: 0.03, label: '3%' },
        { value: 0.04, label: '4%' },
        { value: 0.05, label: '5%' },
        { value: 0.06, label: '6%' },
        { value: 0.09, label: '9%' },
        { value: 0.10, label: '10%' },
        { value: 0.11, label: '11%' },
        { value: 0.13, label: '13%' },
        { value: 0.16, label: '16%' },
        { value: 0.17, label: '17%' }
      ],
      invoiceTaxRateLists: [],
      independentBillingList:[
        { value: 'Y', label: '是' },
        { value: 'N', label: '否' }
      ],
      independentBillingListSelect:[],
      billingTypeList:[],
      billingTypes: [
        { value: '', label: '全部' },
        { value: 'Dzpp', label: '增值税电子普票'},
        { value: 'DZZP', label: '增值税电子专票'},
        { value: 'Zzzp', label: '增值税纸质专票'},
        { value: 'Zzpp', label: '增值税纸质普票'},
        { value: 'SGDP', label: '收购发票(电子)'},
        { value: 'SGZP', label: '收购发票(纸质)'},
        { value: 'QDZP', label: '电子发票(增值税专用发票)'},
        { value: 'QDPP', label: '电子发票(普通发票)'}
      ],
      createdRange: [],
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      tableData: [],
      // 合并单据数据区
      mergeBillData: [],
      //开具数据区
      issueVisible: false,
      issueData: {},
      billingChannel: '',
      recordsLoading: false,
      selectExpended: false,
      openBillType: [],
      taxRates: [],
      rules: {
        orgId: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        taxRate: [{ required: true, message: '请选择活动区域', trigger: 'change' }],
        status: [{ required: true, message: '请选择活动区域', trigger: 'change' }]
      }
    };
  },
  watch: {
    'addParameter.orgId':{
      handler(){
        this.billtypeGetSalBillingTypeList()
      }
    }
  },
  created() {
    this.invoiceTaxRateLists = [{ value: '', label: '全部' }, ...this.invoiceTaxRateList];
    this.splitLists = [{ value: '', label: '全部' }, ...this.splitList];
    this.independentBillingListSelect = [{value: '', label: '全部'},...this.independentBillingList];
    this.getTaxRateList();
    this.getDicts('sys_invoice_tax_rate').then(({ data }) => (this.taxRates = data));
    console.log(this.taxRates);
  },
  methods: {
    // 初始化列表
    async getTaxRateList() {
      let res = await getTaxRateList(this.queryForm);
      if (res.success) {
        this.tableData = res.data.records;
        this.dataTotal = res.data.total
      }
    },
    async billtypeGetSalBillingTypeList() {
      let res = await billtypeGetSalBillingTypeList({scene: 'LPSM',orgId:this.addParameter.orgId, status:true});
      if (res.success) {
        if(res.data.records.length) {
          this.billingTypeList = this.fileters(this.billingTypes,res.data.records[0].billingTypeList)
        }else {
           this.toast('请到类型管理维护发票类型!', 'warning')
           this.billingTypeList = []
        }
      }
    },
    fileters(arr1,arr2){
      console.log(arr1,arr2)
      return arr1.filter(item => arr2.some(val => val == item.value))
    },
    // 新增
    async addSalTaxRate(){
      let res = await addSalTaxRate(this.addParameter);
      if (res.success) {
        this.dialogAdd = false
        this.toast('新增成功', 'success')
        this.getTaxRateList()
      }
    },
    // 修改
    async revampTaxRateList(){
      let res = await revampTaxRateList(this.addParameter);
      if (res.success) {
        this.dialogAdd = false
        this.toast('修改成功', 'success')
        this.getTaxRateList()
      }
    },
    async salTaxRate(id){
      let res = await salTaxRate(id)
      if (res.success) {
        this.addParameter = res.data
      }
    },
    addAffirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.ModifyOrAdd == '修改'){
            this.revampTaxRateList()
          }else {
            this.addSalTaxRate()
          }
        } else {
          return false;
        }
      });
    },
    // 删除
    async handleDelete(scope){
      let res = await taxRateList(scope.id)
      if(res.success) {
        this.toast('删除成功', 'success')
        this.getTaxRateList()
      }
    },
    async handleDeleteAllSelect(){
      let ids = this.mergeBillData.map(item => item.id)
      let res = await taxRateBatchDelete(ids)
      if(res.success) {
        this.toast('删除成功', 'success')
        this.getTaxRateList()
      }
    },
    handleDetailInvoice(scope, type) {
      if (type == 'Modify') {
        this.ModifyOrAdd = '修改';
        this.salTaxRate(scope.id)
      } else {
        this.ModifyOrAdd = '新增';
        this.addParameter = {
          taxRate: '',
          uniqueCode: '',
          billingType: '',
          name: '',
          status: '1',
          separateBilling:'N'
      };
      }
      this.dialogAdd = true;
    },
    // 初始化开票设备
    async handleInitEquipment() {
      if (!this.queryForm.orgId) {
        return;
      }
      const { success, data } = await getEquipmentByOrgId({ orgId: this.queryForm.orgId });
      if (success) {
        this.billingChannel = data.billingChannel;
      }
    },

    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    // 重置筛选列表
    handleReset() {
      this.queryForm = {
        taxRate: '',
        uniqueCode: '',
        billingType: '',
        orgId: 0,
        name: '',
        status: '',
        separateBilling:''
      };
    },
        //格式发票类型
    fmtBillingType (row) {
      return this.handleValueToLabel('BillingType', row.billingType)
    },
    // 表格值格式化
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '可开票税率':
          return  value * 100 + '%';
        case '启用状态':
          return value == 0 ? '启用' : '禁用'
        case '是否独立开票':
          return value == 'Y' ? '是' : '否'
        default:
          return value;
      }
    },
    // 条件查询
    handleQuery() {
      this.getTaxRateList();
    },
    // 选中数据
    handleSelectionChange(invoices) {
      this.mergeBillData = invoices;
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryForm.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.queryForm.page = value;
      this.handleQuery();
    },
    // 记住选中
    handleGetRowKey(row) {
      return row.id;
    },
    // 发票删除
    handleInvoiceDelete(invoice) {}
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/select.scss';

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    span {
      font-size: 16px;
      color: #666666;
    }

    .el-button {
      width: 80px;
      padding: 0;
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 20px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.dialog-content {
  margin-top: -6px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: left;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-body {
  margin: -16px 24px 0 24px;

  .body-top {
    .top-first-row {
      display: flex;
      margin-bottom: 12px;
    }

    .top-second-row {
      display: flex;
      text-align: left;
      margin-bottom: 24px;

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 20em;
      }
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .body-bottom {
    text-align: left;
    border-top: 1px solid #e9e9e9;
    margin: 0 -24px;
    padding: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

.limit-dialog {
  margin-top: -20px;
  padding-left: 20px;
  text-align: left;
  font-size: 14px;

  .data {
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e9e9;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
  }

  .message {
    padding: 24px 0;
    color: #f5222d;
  }
}

.el-dialog__footer {
  padding: 10px 24px 24px;
  text-align: right;
  box-sizing: border-box;
}
.add_style {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 20px 0 0;
    text-align: left;
  }
  ::v-deep .el-form-item__error {
    top: 28px;
    font-size: 12px;
  }
}
</style>
