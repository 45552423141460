<script>
import { BILLING_CONFIGS } from "@/views/invoice-open/billing-config/billing.config.ts"
import { listTntConfig, saveBatch, getTntConfig } from "@/service/system/config" 
export default {
  name: "billingConfig",
  data() {
    return {
      configs: BILLING_CONFIGS,
      invoiceType:'',
      fleldTradeCode:'',
      merchandiseTaxRate:'',
      num:0
    }
  },
  created() {
    this.handleInit()
    this.getTntConfig()
  },
  watch:{
    num:{
      handler(val){
        if(val < 0) this.num = 0
        if(val > 100) this.num = 100
      }
    }
  },
  methods: {
    async getTntConfig(){
      let {success,data} = await getTntConfig('BILL_O2I_MERGE_RULE')
      if(success) {
        this.invoiceType = data.toString().search('BILLING_TYPE')
        this.fleldTradeCode = data.toString().search('FIELD_TRADE_CODE')
        this.merchandiseTaxRate = data.toString().search('MERCHANDISE_TAX_RATE')
        if(this.invoiceType != -1){
          this.invoiceType = true
        }else {
          this.invoiceType = false
        }
        if(this.fleldTradeCode != -1){
          this.fleldTradeCode = true
        }else {
          this.fleldTradeCode = false
        }
        if(this.merchandiseTaxRate != -1){
          this.merchandiseTaxRate = true
        }else {
          this.merchandiseTaxRate = false
        }
        // console.log(this.invoiceType,this.fleldTradeCode,this.merchandiseTaxRate,this.configs,'this.invoiceType')
        this.configs = this.configs.map(e => {
          if(e.title == '发票分组规则') {
              e.items.map(i => {
              if(i.label == '同业务单号'){
                i.checked = this.fleldTradeCode
              }else if(i.label == '同发票税率'){
                i.checked = this.merchandiseTaxRate
              } 
            })
          }
          return e
        })
      }
      // console.log(this.configs,'configs')
    },
    // 初始化配置项 - 勾选
    async handleInit() {
      const { success, data } = await listTntConfig({codes:["BILL_O2I_MERGE_RULE", "MANUAL_SEARCH_BUYER","OFFSET_NEGATIVE_PERCENT"]})
      if (success && data) {
        const rules = [];
        for(let prop in data){
          rules.push(JSON.parse(data[prop]))
        }
        this.num = JSON.parse(data.OFFSET_NEGATIVE_PERCENT)[0]
        this.configs.forEach(e => {
          e.items.forEach(i => {
            if (i.rules.reduce((r, c) => rules.includes(c) && r, true)) {
              i.checked = true
            }
          })
        });
      } else {
        this.toast("未获取到配置项数据", "warning")
      }
    },
    // 重置
    handleReset() {
      this.configs = BILLING_CONFIGS
    },

    // 渲染模型转 入参模型集合
    handleBuildParam() {
      let origin = {};
      let params = [];
      this.configs.map(({ code, name, remark }) => {
        return { code, name, remark }
      })
      // 规则权限拼接 相同code下拼接rules
      this.configs.forEach(({ code, name, items, remark }) => {
        if (!origin[code]) {
          origin[code] = { type: "TNT", status: '0', name, code, remark, value: [] }
        }
        items.forEach(i => {
          if (i.checked) origin[code].value.push(...i.rules)
        })
      })

      for (let k in origin) {
        origin[k].value = JSON.stringify(origin[k].value)
        params.push(origin[k])
      }
      for(let i=0; i<params.length; i++){
        let item = params[i]
        if(item.code === "OFFSET_NEGATIVE_PERCENT"){
          item.value = `[${this.num}]`
        }
      }
      this.handleSave(params);
      console.log(params)
    },
    // 保存
    async handleSave(params) {
      const { success } = await saveBatch(params);
      if (success) this.toast('配置修改成功', 'success')
    },
    // 勾选切换
    handleChange(block, val){
      if(block.type === 'radio'){
        block.items.forEach(i => i.checked = false)
        val.checked = true
      }
    }
  }
}
</script>

<template>
  <div style="background-color: #fff;">
    <header>
      <span class="require">开票配置</span>
      <span>(此页面主要用作原始订单生成待开发票或对接外部系统时接收待开发票数据时的拆分、合并规则配置)</span>
    </header>

    <section>
      <div class="_b" v-for="block in configs" :key="block.title">
        <span class="label">{{ block.title }}</span>
        <article>
          <div v-for="item in block.items" :key="item.label">
            <el-checkbox v-model="item.checked" :disabled="item.disabled" @change="handleChange(block, item)" />
            <span class="label">{{ item.label }}</span>
            <div class="_m" v-if="item.msg">
              <i class="el-icon-info icon"></i>
              <span>{{ item.msg }}</span>
            </div>
            <div style="width:80%" v-if="item.label === '负数行明细冲抵'"> 
              <el-input style="margin-left:160px" v-model.number="num"  placeholder="请输入冲抵比例" type="text"></el-input>
              <span style="margin-left:6px">%</span>
            </div>
          </div>
        </article>
      </div>
    </section>

    <footer>
      <el-button type="default" @click="handleReset">重置</el-button>
      <el-button type="primary" @click="handleBuildParam">保存</el-button>
    </footer>
  </div>
</template>

<style scoped lang="scss">
header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 34px;
  background-color: #fff;
  border-bottom: 1px solid #e9e9e9;

  .require {
    color: #333333;
    font-size: 16px;
    font-weight: 500;
  }
  .require:before {
    content: "";
    height: 22px;
    margin-right: 8px;
    border-right: 2px solid #333;
  }

  span:last-child {
    color: #f5222d;
    padding-left: 16px;
  }
}

section {
  padding: 24px;
  margin: 24px 24px 0;
  border: 1px solid rgba(0, 0, 0, 0.15);

  ._b:not(:last-child) {
    margin-bottom: 24px;
  }
  ._b {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .label {
      min-width: 140px;
      display: inline-block;
    }
  }

  article {
    flex: auto;
    display: flex;
    font-size: 14px;
    color: #666666;
    flex-direction: column;

    .el-checkbox {
      margin-right: 8px;
    }

    div {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      align-items: baseline;
      flex-wrap: wrap;

      ._m {
        display: flex;
        padding: 12px;
        color: #999999;
        border-radius: 2px;
        align-items: center;
        background-color: #fafafa;

        i {
          margin-right: 4px;
        }
      }
    }
  }
}

footer {
  display: flex;
  padding: 8px 0 24px;
  flex-direction: row;
  justify-content: center;
  background-color: #fff;
}
</style>
