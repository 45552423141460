<template>
  <div class="content-wrap-split">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm" label-width="100px">
          <div>
            <el-form-item label="组织编码" prop="uniqueCode">
              <el-input v-model.trim="queryForm.uniqueCode" placeholder="请输入组织编码" maxlength="30" @keyup.enter.native="handleGetBillingTradeList()" />
            </el-form-item>
            <el-form-item label="组织名称" prop="queryForm">
              <OrganizationSelect :model="queryForm"></OrganizationSelect>
            </el-form-item>
            <el-form-item label="超行拆分" prop="rowLimitSwitch">
              <el-select v-model="queryForm.rowLimitSwitch" placeholder="请选择">
                <el-option v-for="item in splitLists" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="select-button">
          <select-button @list-close="handleListClose" @list-show="handleListShow" @select="handleQuery" @reset="handleReset"></select-button>
        </div>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="main-top">
          <span>票据列表</span>
          <div>
            <el-button @click="handleDetailInvoice" type="primary">新 增</el-button>
            <el-button :disabled="mergeBillData.length == 0 ? true : false" @click="salLimitDelALL()" type="primary">批量删除</el-button>
          </div>
        </div>
        <div class="content-table">
          <el-table border stripe :data="tableData" ref="retailTable" v-loading="recordsLoading" :row-key="handleGetRowKey" :header-cell-style="handleHeaderCellStyle" @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column type="selection" width="50" :reserve-selection="true" fixed="left" />
            <el-table-column type="index" label="序号" width="60" fixed="left" class-name="dl-pointer" />
            <el-table-column prop="uniqueCode" label="组织编码" width="180" />
            <el-table-column prop="name" label="组织名称" min-width="220" show-overflow-tooltip />
            <el-table-column prop="rowLimitSwitch" :formatter="handleTableValueFormat" label="是否超行拆分发票" width="170" />
            <el-table-column prop="rowInvoiceLimit" label="限制明细行数" min-width="120" />
            <el-table-column prop="updaterName" label="更新人" width="170" />
            <el-table-column prop="updated" label="更新时间" width="170" />
            <el-table-column prop="operate" label="操作" fixed="right" width="110">
              <template slot-scope="scope">
                <el-button type="text" @click="handleDetailInvoice(scope.row, 'Modify')">修改</el-button>
                <el-button type="text" @click="salLimitDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="queryForm.page" :page-sizes="pageSizes" :page-size="queryForm.size" layout="total, sizes, prev, pager, next" :total="dataTotal"></el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <div class="add_style">
      <el-dialog :title="ModifyOrAdd" :visible.sync="dialogAdd" width="600px">
        <el-form :model="addParameter" :rules="rules" ref="ruleForm">
          <el-form-item prop="orgId" style="margin-bottom: 15px" label="组织名称" :label-width="formLabelWidth">
            <OrganizationSelect :is-add-all="false" allLabel="请选择" :model="addParameter"></OrganizationSelect>
          </el-form-item>
          <el-form-item prop="rowLimitSwitch" style="margin-bottom: 15px" label="超行拆分发票" :label-width="formLabelWidth">
            <el-select v-model="addParameter.rowLimitSwitch" placeholder="请选择">
              <el-option v-for="item in splitList" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <span class="slice_style">
              <i class="el-icon-warning-outline"></i>
              <span  style="font-size: 12px">当明细行数超过设定值，系统自动拆分发票。</span>
            </span>

          </el-form-item>
          <el-form-item prop="rowInvoiceLimit" style="margin-bottom: 15px" label="限制明细行数" :label-width="formLabelWidth">
            <el-input  type="Number" :disabled="addParameter.rowLimitSwitch == 'N'" v-model="addParameter.rowInvoiceLimit" placeholder=""></el-input>
            <span>(行)</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogAdd = false">取 消</el-button>
          <el-button type="primary" @click="addAffirm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>

import { tableStyle, dateOption, changeMethods, commonsMethods } from '@/util/mixins';
import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import { salLimitList, salLimitDelALL, salLimitDel, addSalLimit, getSalLimit, alterSalLimit } from '@/service/invoiceOpenConfiguration.js';
export default {
  name: 'splitRule',
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  components: {
    OrganizationSelect
  },
  props: [],
  data() {
    return {
      dialogAdd: false,
      ModifyOrAdd:'',
      addParameter: {
        name: '',
        orgId: '',
        rowLimitSwitch: 'N',
        rowInvoiceLimit: 0
      },
      formLabelWidth: '120px',
      queryForm: {
        uniqueCode: '',
        rowLimitSwitch: '',
        orgId: 0,
        name: '',
        page: 1,
        size: 15
      },
      splitLists: [],
      splitList: [
        {
          value: 'Y',
          label: '启用'
        },
        {
          value: 'N',
          label: '禁用'
        }
      ],
      createdRange: [],
      dataTotal: 0,
      pageSizes: [15, 30, 50, 100],
      tableData: [],
      // 合并单据数据区
      mergeBillData: [],
      //开具数据区
      issueVisible: false,
      issueData: {},
      billingChannel: '',
      recordsLoading: false,
      selectExpended: false,
      openBillType: [],
      rules: {
        orgId: [{ required: true, message: '请选择', trigger: 'change' }],
        rowLimitSwitch: [{ required: true, message: '请输入', trigger: 'change' }],
        rowInvoiceLimit: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    };
  },
  watch: {
    'addParameter.rowLimitSwitch': {
      handler(val) {
        if (val == 'N') {
          this.addParameter.rowInvoiceLimit = 0;
        }
      }
    }
  },
  created() {
    this.splitLists = [{ value: '', label: '全部' }, ...this.splitList];
    this.salLimitList()
  },
  methods: {
    // 初始化列表
    async salLimitList() {
      let res = await salLimitList(this.queryForm);
      if (res.success) {
        this.tableData = res.data.records;
        this.dataTotal = res.data.total
      }
    },
    // 删除
    async salLimitDel(scope){
      let res = await salLimitDel(scope.id)
      if(res.success) {
        this.toast('删除成功', 'success')
        this.salLimitList()
      }
    },
    async salLimitDelALL(){
      let ids = this.mergeBillData.map(item => item.id)
      let res = await salLimitDelALL(ids)
      if(res.success) {
        this.toast('删除成功', 'success')
        this.salLimitList()
      }
    },
    async addSalLimit(){
      let res = await addSalLimit(this.addParameter)
      console.log(res)
      if (res.success) {
        this.dialogAdd = false
        this.salLimitList()
        this.toast('新增成功', 'success')
      }
    },
    async getSalLimit(id){
      let res = await getSalLimit(id)
      console.log(res)
      if (res.success) {
        this.addParameter = res.data
      }
    },
    async alterSalLimit(){
      let res = await alterSalLimit(this.addParameter)
      console.log(res)
      if (res.success) {
        this.dialogAdd = false
        this.toast('限行拆分规则保存成功。', 'success')
        this.salLimitList()
      }
    },
    addAffirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.ModifyOrAdd == '修改'){
            this.alterSalLimit()
          }else {
            this.addSalLimit()
          }
          this.addParameter = {
            name: '',
            orgId: '',
            rowLimitSwitch: 'N',
            rowInvoiceLimit: 0
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleDetailInvoice(scope, type) {
      if (type == 'Modify') {
        this.ModifyOrAdd = '修改';
        this.getSalLimit(scope.id)
      } else {
        this.addParameter = {
        name: '',
        orgId: '',
        rowLimitSwitch: 'N',
        rowInvoiceLimit: 0
      },
        this.ModifyOrAdd = '新增';
      }
      this.dialogAdd = true;
    },
    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    // 重置筛选列表
    handleReset() {
      this.queryForm = {
        uniqueCode: '',
        rowLimitSwitch: '',
        orgId: 0,
        name: '',
        page: 1,
        size: 15
      };
    },
    // 表格值格式化
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case '是否超行拆分发票':
          return value == 'Y' ? '启用' : '禁用'
        case '发票类型':
          return this.handleValueToLabel('rowLimitSwitch', value);
        case '业务类型':
          return this.handleValueToLabel('BillingFrom', value);
        case '单据类型':
          return this.getDictLabel(this.openBillType, value);
        case '数据来源':
          return this.handleValueToLabel('SystemSource', value);
        default:
          return value;
      }
    },
    // 条件查询
    handleQuery() {
      this.salLimitList()
    },
    // 选中数据
    handleSelectionChange(invoices) {
      this.mergeBillData = invoices;
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryForm.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.queryForm.page = value;
      this.handleQuery();
    },
    // 记住选中
    handleGetRowKey(row) {
      return row.id;
    },
    // 发票删除检查
    handleInvoiceDelete(invoice) {}
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/select.scss';

.content-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 180px);

  .main-top {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;

    span {
      font-size: 16px;
      color: #666666;
    }

    .el-button {
      width: 80px;
      padding: 0;
    }
  }

  .content-table {
    .el-button {
      padding: 0;
    }
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 20px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
  }
}

.dialog-content {
  margin-top: -6px;
  margin-left: 24px;
  margin-right: 24px;
  text-align: left;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.dialog-body {
  margin: -16px 24px 0 24px;

  .body-top {
    .top-first-row {
      display: flex;
      margin-bottom: 12px;
    }

    .top-second-row {
      display: flex;
      text-align: left;
      margin-bottom: 24px;

      span {
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 20em;
      }
    }

    label {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
    }

    span {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 20px;
    }
  }

  .body-bottom {
    text-align: left;
    border-top: 1px solid #e9e9e9;
    margin: 0 -24px;
    padding: 24px;

    span {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #f5222d;
      line-height: 20px;
    }
  }
}

.limit-dialog {
  margin-top: -20px;
  padding-left: 20px;
  text-align: left;
  font-size: 14px;

  .data {
    padding-bottom: 25px;
    border-bottom: 1px solid #e9e9e9;

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
    }
  }

  .message {
    padding: 24px 0;
    color: #f5222d;
  }
}

.el-dialog__footer {
  padding: 10px 24px 24px;
  text-align: right;
  box-sizing: border-box;
}
.add_style {
  ::v-deep .el-dialog .el-dialog__body {
    padding: 20px 0 0;
    text-align: left;
  }
  ::v-deep .el-form-item__error {
    top: 28px;
    font-size: 12px;
  }
}
.slice_style {
  background-color: #f2f2f2;
  margin-left: 10px;
  .el-icon-warning-outline {
    font-size: 13px;
    margin: 0 3px;
    color: #3b96ff;
  }
}
</style>
